import React from 'react'
import { Link } from 'react-router-dom'
import "./home.scss"
export default function Home() {
  return (
    <div className="home">
      <div className="row">
        <div className="col">
          <Link to="/sw"><img src='./app.icon.sw.png' alt="Swinda"></img></Link>
          <div className="name">Swinda (iOS)</div>
        </div>


        <div className="col">
        <Link to="/ca"><img src='./app.icon.ca.png' alt="Catscha"></img></Link>
          <div className="name">Catscha (iOS)</div>
        </div>

        <div className="col">
        <Link to="/we"><img src='./app.icon.we.png' alt="WE"></img></Link>
          <div className="name">WE (iOS)</div>
        </div>
        <div className="col">
        <Link to="/su"><img src='./app.icon.su.png' alt="WE"></img></Link>
          <div className="name">Sudoku (iOS)</div>
        </div>

        <div className="col">
        <Link to="/mba"><img src='./app.icon.mba.png' alt="myBOY (Android)"></img></Link>
          <div className="name">myBOY (Android)</div>
        </div>

        <div className="col">
        <Link to="/swa"><img src='./app.icon.swa.png' alt="Swinda (Android)"></img></Link>
          <div className="name">Swinda (Android)</div>
        </div>

      </div>
    </div>
  )
}
